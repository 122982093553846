@font-face {
  font-family: xmas;

}
body{


}
.App {
  text-align: center;


}
video {
  
  width: 100%;
  height: auto;
  position: fixed;
  top: 0;
  left: 0;
}


body{


}
.topcorner{
  position:absolute;
  top:10px;
  right: 10px;

  font-weight: bolder;
}
.wallet-adapter-button{
  /* background-image: linear-gradient(to right, #740082, #d1006d, #ff5b49, #ffaf21, #f2ff43); */
  background-color: #ffff !important;
  color: black !important;
  border-radius: 10px !important;
  box-shadow: 10px 10px 5px rgb(8, 8, 8);
font-family: 'Acme', sans-serif !important;

}
.center{
  position: absolute; 
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  
}
img{
  width: 50%;
  height: 50%;
  border-radius: 10px;
  box-shadow: 10px 10px 5px rgb(8, 8, 8);
}
.btn {
font-family: 'Acme', sans-serif !important;
  border-radius: 10px;
  font-size: 1.7vw;
  padding: 1.2rem;
  background-color: #ffff;
  color: black;
  font-weight: bolder;
  cursor: pointer;
  margin-top: 1.3rem;
  box-shadow: 10px 10px 5px rgb(8, 8, 8);
}
.btn:active {
  background-color: #3e8e41;
  box-shadow: 0 5px #666;
  transform: translateY(4px);
}
@media screen and (max-width:1024px)  {
  img{
    width: 100%;
    height: 100%;
    border-radius: 30px;
  }
  .btn {
 
    font-size: 5.2vw;
  }
  video {
    object-fit: cover;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
  }
}
@media screen and (max-width:800px)  {
  video {
    object-fit: cover;
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
  }
  
}


